import React from 'react';
import styled from 'styled-components';
import LoadingSpinnerM from '@pedidosya/web-fenix/animations/LoadingSpinnerM';
import MicrositeWrapper from './MicrositeWrapper';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`;

const MicrositeLoader = () => {
  return (
    <MicrositeWrapper>
      <LoaderContainer>
        <LoadingSpinnerM />
      </LoaderContainer>
    </MicrositeWrapper>
  );
};

export default MicrositeLoader;
