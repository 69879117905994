import { CLOSE, REDIRECT } from '../events';

const deepLinkMatchers = [
  {
    matcher: 'pedidosya://feedback',
    url: '/feedback/web',
  },
  {
    matcher: 'pedidosya://helpcenter',
    url: '/customer-service',
  },
  {
    matcher: 'pedidosya://onlinehelp',
    url: '/customer-service',
  },
];

function mapDeepLinkToWebLink(deeplink) {
  const target = deepLinkMatchers.find(({ matcher }) => deeplink && deeplink.startsWith(matcher));
  if (target) {
    return deeplink.replace(target.matcher, target.url);
  }
  return '';
}

export default function webEvent(context) {
  if (context?.event === REDIRECT) {
    const url = mapDeepLinkToWebLink(context?.payload?.target);
    if (url) {
      window.location.href = url;
    }
  }

  if (context?.event === CLOSE) {
    window.location.href = '/home-page';
  }
}
