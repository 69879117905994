import React from 'react';
import LoadingSpinnerM from '@pedidosya/web-fenix/animations/LoadingSpinnerM';

const ErrorPageComponent = React.lazy(() => import('./ErrorPage'));

const ErrorPageLoader = (props) => {
  return (
    <React.Suspense fallback={<LoadingSpinnerM />}>
      <ErrorPageComponent {...props} />
    </React.Suspense>
  );
};

export default ErrorPageLoader;
