import React from 'react';
import styled from 'styled-components';
import ErrorPage from '../ui/organisms/ErrorPage/ErrorPageLoader';
import messages from '@app/pages/completedOrder/messages';
import locationNotFoundImage from '@app/assets/icons/not-found-location.svg';
import { useTranslate } from '@app/providers/I18nProvider';
import { isEmpty } from '@app/utils/string';
import Button from '@pedidosya/web-fenix/system/Button';
import { getLogger } from '@app/logger';

const Image = styled.img``;

const MicrositeError = ({ title, description, IconComponent, controls, debugMessage }) => {
  const { translate } = useTranslate();
  const errorTitle = isEmpty(title) ? translate(messages.noDetailTitle) : title;
  getLogger().error(debugMessage);

  function reloadPage() {
    window.location.reload();
  }

  return (
    <ErrorPage
      anchoredToParent
      title={errorTitle}
      description={!isEmpty(title) ? description : translate(messages.noDetailDescription)}
      IconComponent={
        IconComponent ? (
          IconComponent
        ) : (
          <Image src={locationNotFoundImage} placeholder={locationNotFoundImage} alt={errorTitle} />
        )
      }
    >
      {controls ? controls : <Button onClick={reloadPage} label={translate(messages.retry)} />}
    </ErrorPage>
  );
};

export default MicrositeError;
