export const fetcher = (key) => {
  const [url, token] = key;
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', token);
  return fetch(url, { headers })
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Error getting completed page, status: ${res.status}, url: ${url}`);
      }
      return res.json();
    })
    .then((res) => res.data);
};
