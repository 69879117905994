import React from 'react';
import PropTypes from 'prop-types';
import App from './App';
import MicrositeInitializer from './components/Microsite/MicrositeInitializer';

const Root = (shellData) => {
  return <MicrositeInitializer shellData={shellData} AppComponent={App} />;
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.object,
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
