import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import styled from 'styled-components';
import fetcher from '@app/pages/completedOrder/utils/fetcher';
import { isMobile } from '@app/utils/is-mobile';
import LoadingSpinnerM from '@pedidosya/web-fenix/animations/LoadingSpinnerM';
import actionManager from '@app/pages/completedOrder/actions';
import Brick from '@app/pages/completedOrder/components/Brick';
import { getLogger } from '@app/logger';
import MicrositeError from '@app/components/Microsite/MicrositeError';
import { FLAGS } from '@commons/fwf/constants';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 32px; */

  & > * {
    margin-bottom: 32px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

function CompletedOrder({ history, tracker, context, queryParams, platform, flags }) {
  const { orderId, c } = queryParams;
  const {
    data: response,
    error,
    isLoading,
  } = useSWR(
    [`${context.apiBaseUrl}/v1/orders/${orderId}/completed`, context?.app?.auth?.token],
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  useEffect(() => {
    if (response && response?.data?.actions) {
      const actions = actionManager.buildHandler(response.data.actions);
      actions();
    }
  }, [response]);

  if (isLoading) return <LoadingSpinnerM />;

  if (error || !response.success) {
    const errorMessage = `[MICRO] error getting order completed response for orderId ${orderId}: ${JSON.stringify(
      error || (response && response?.message) ? response.message : 'unknown',
    )}`;
    return <MicrositeError debugMessage={errorMessage} />;
  }

  const bannerTheme = flags[FLAGS.WEB_ENABLE_QATAR_THEME.KEY]
    ? flags[FLAGS.WEB_ENABLE_QATAR_THEME.KEY]?.variation
    : 'default';

  getLogger().info(`Using theme: ${bannerTheme} for orderId: ${orderId} country: ${c}`);

  const customProps = {
    isMobile: isMobile(context?.app?.platformKey),
    countryId: c || context?.app?.country?.shortName,
    bannerTheme,
  };

  const bricks = response.data.bricks.map((brick, idx) => {
    const key = `${brick.type}-${idx}`;
    return <Brick customProps={customProps} key={key} data={brick} order={idx} />;
  });

  return <Container>{bricks}</Container>;
}

CompletedOrder.propTypes = {
  viewData: PropTypes.object,
};

export default CompletedOrder;
