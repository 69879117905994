import { defineMessages } from 'react-intl';

export default defineMessages({
  noDetailTitle: {
    id: 'app.orders.orderstatus.detail.loadingError.title',
    defaultMessage: 'Detalles no disponibles',
  },
  noDetailDescription: {
    id: 'app.orders.orderstatus.detail.loadingError.description',
    defaultMessage: 'Se ha producido un error inesperado.',
  },
  retry: {
    id: 'app.orders.orderstatus.actions.retry',
    defaultMessage: 'Reintentar',
  },
});
