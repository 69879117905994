import { keyframes } from 'styled-components';

export const createStarMovement = ({ translateX, translateY, rotateFrom, rotateTo }) => keyframes`
    from { 
        transform: translateX(0) translateY(0) rotate(${rotateFrom}deg) scale(1);
        opacity: 0;
    }
    5% { 
        opacity: 1;
    }
    40% {
        transform: translateX(${translateX * 0.4}px) translateY(${
  translateY * 0.4 - 30
}px) rotate(${rotateFrom + (rotateTo - rotateFrom) * 0.4}deg) scale(1.25);
    }
    65% {
        transform: translateX(${translateX * 0.65}px) translateY(${
  translateY * 0.65 - 20
}px) rotate(${rotateFrom + (rotateTo - rotateFrom) * 0.65}deg) scale(1.5);
    }
    85% {
        transform: translateX(${translateX * 0.85}px) translateY(${
  translateY * 0.85 - 10
}px) rotate(${rotateFrom + (rotateTo - rotateFrom) * 0.85}deg) scale(1.25);
    }
    to { 
        transform: translateX(${translateX}px) translateY(${translateY}px) rotate(${rotateTo}deg) scale(1);
    }
`;

export const createExpandStar = ({ translateX, translateY, finalRotate }) => keyframes`
    from { 
        transform: translateX(${translateX}px) translateY(${translateY}px) rotate(${finalRotate}deg) scale(1);
    }

    25% {
        transform: translateX(${translateX}px) translateY(${translateY}px) rotate(${finalRotate}deg) scale(1.2);
    }

    50% { 
        transform: translateX(${translateX}px) translateY(${translateY}px) rotate(${finalRotate}deg) scale(1);
    }

    to { 
        transform: translateX(${translateX}px) translateY(${translateY}px) rotate(${finalRotate}deg) scale(1);
    }
`;

export const movementSmallStarLeft = createStarMovement({
  rotateFrom: -50,
  rotateTo: 50,
  translateX: -75,
  translateY: -58,
});

export const smallStarExpand = createExpandStar({
  translateX: -75,
  translateY: -58,
  finalRotate: 50,
});

export const movementLargeStarLeft = createStarMovement({
  rotateFrom: 180,
  rotateTo: 360,
  translateX: -70,
  translateY: -80,
});

export const largStarLeft = createExpandStar({
  translateX: -70,
  translateY: -80,
  finalRotate: 360,
});

export const movementMidStarLeft = createStarMovement({
  rotateFrom: -56,
  rotateTo: 56,
  translateX: -75,
  translateY: -45,
});

export const midStarLeft = createExpandStar({
  translateX: -75,
  translateY: -45,
  finalRotate: 56,
});

export const movementSmallStarRight = createStarMovement({
  rotateFrom: 200,
  rotateTo: 360,
  translateX: 78,
  translateY: -65,
});

export const smallStarRight = createExpandStar({
  translateX: 78,
  translateY: -65,
  finalRotate: 360,
});

export const movementLargeStarRight = createStarMovement({
  rotateFrom: 180,
  rotateTo: 360,
  translateX: 75,
  translateY: -55,
});

export const largeStarRight = createExpandStar({
  translateX: 75,
  translateY: -55,
  finalRotate: 360,
});

export const movementMidStarRight = createStarMovement({
  rotateFrom: 250,
  rotateTo: 360,
  translateX: 60,
  translateY: -85,
});

export const midStarRight = createExpandStar({
  translateX: 60,
  translateY: -85,
  finalRotate: 360,
});

export const fadeInBag = keyframes`
from { transform: rotate(20deg) scale(0); }
50% { transform: rotate(10deg) scale(1.7);}
to { transform: rotate(0deg) scale(1);}
`;
