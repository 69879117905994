import React from 'react';
import FeedbackPage from '@pedidosya/web-fenix/system/FeedbackPage';
import FooterButton from '@pedidosya/web-fenix/system/FooterButton';
import TopAppBar from '@pedidosya/web-fenix/system/TopAppBar';
import ButtonIcon from '@pedidosya/web-fenix/system/ButtonIcon';
import HelpSupport from '@pedidosya/web-fenix/icons/HelpSupport';
import Close from '@pedidosya/web-fenix/icons/Close';
import { capitalize } from '@app/utils/string';
import actionManager from '@app/pages/completedOrder/actions';
import CongratsAnimation from './CongratsAnimation';
import EventManager, { CLOSE } from '../completedOrder/events';

const getElements = (data) => {
  const bricks = Object.groupBy(data.bricks, (brick) => brick.type);
  const texts = bricks['textBlock'][0].data;
  const buttons = bricks['button'].reduce(
    (acc, brick) => {
      if (brick.variant === 'primary') {
        acc.primary = { ...brick, text: capitalize(brick.data.text) };
      } else {
        acc.tertiary = { ...brick, text: capitalize(brick.data.text) };
      }
      return acc;
    },
    { primary: {}, tertiary: {} },
  );

  return {
    title: texts.title,
    description: texts.subtitle,
    primaryBtn: buttons.primary,
    tertiaryBtn: buttons.tertiary,
  };
};

const CongratsPage = ({ data }) => {
  const { title, description, primaryBtn, tertiaryBtn } = getElements(data);

  const handleBtnClick = ({ actions }) => {
    const handler = actionManager.buildHandler(actions);
    const hasRedirectEvent =
      actions.filter((action) => action.type === 'redirect').length > 0 || false;

    handler();
    if (hasRedirectEvent) {
      setTimeout(() => {
        // because native view is not really navigating to a new page
        // here it will wait 2 seconds to make a hard refresh and show
        // the animation again.
        window.location.reload();
      }, 2000);
    }
  };

  const handleClose = () => {
    EventManager.dispatch(CLOSE, {});
  };

  return (
    <FeedbackPage variant="simple" title={title} description={description}>
      <FeedbackPage.TopBar left="empty" middle="title" titleValue="Pedido entregado" right="icon">
        <TopAppBar.Right>
          <ButtonIcon size="medium" icon={<Close size="medium" />} onClick={handleClose} />
        </TopAppBar.Right>
      </FeedbackPage.TopBar>
      <FeedbackPage.CustomImage>
        <CongratsAnimation />
      </FeedbackPage.CustomImage>
      <FeedbackPage.FooterButton>
        <FooterButton.Button
          label={primaryBtn.text}
          size="large"
          hierarchy="primary"
          fullWidth
          onClick={() => handleBtnClick(primaryBtn)}
        />
        <FooterButton.Button
          label={tertiaryBtn.text}
          size="large"
          hierarchy="tertiary"
          fullWidth
          icon={<HelpSupport />}
          onClick={() => handleBtnClick(tertiaryBtn)}
        />
      </FeedbackPage.FooterButton>
    </FeedbackPage>
  );
};

export default CongratsPage;
