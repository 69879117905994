function pageEventService() {
  const eventHandlers = {};

  const registerHandler = (eventName, handler) => {
    if (!eventHandlers[eventName]) {
      eventHandlers[eventName] = [];
    }

    eventHandlers[eventName].push(handler);
  };

  const unregisterHandler = (eventName, handler) => {
    if (!eventHandlers[eventName]) {
      return;
    }
    const index = eventHandlers[eventName].findIndex((handlerFunc) => handlerFunc === handler);
    if (index !== undefined) {
      eventHandlers[eventName].splice(index, 1);
    }
  };

  const fireEvent = (eventName, callback, data = null) => {
    if (!eventHandlers[eventName]) {
      return;
    }

    Promise.all(eventHandlers[eventName].map((handler) => handler(data))).then(() => callback());
  };

  return {
    registerHandler,
    unregisterHandler,
    fireEvent,
  };
}

const service = pageEventService();

export default service;
