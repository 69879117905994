import React from 'react';

const LargeStar = () => {
  return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.9765 18.4945L29.9445 12.8693C30.8345 11.1826 29.4039 9.20749 27.5238 9.52709L21.2536 10.5929L16.8209 6.0319C15.4917 4.66424 13.1712 5.41439 12.8942 7.30132L11.9702 13.594L6.26267 16.4003C4.5512 17.2418 4.54757 19.6805 6.25653 20.5271L11.9557 23.3504L12.8609 29.6458C13.1324 31.5335 15.4506 32.2906 16.7839 30.9268L21.2301 26.379L27.4971 27.4635C29.3763 27.7887 30.8127 25.8179 29.9277 24.1285L26.9765 18.4945Z"
        fill="#F5F04F"
        stroke="white"
        strokeWidth="4.19285"
      />
      <path
        d="M25.42 18.4922L28.726 12.2265C29.0832 11.5496 28.5091 10.757 27.7546 10.8853L20.7704 12.0725L15.8329 6.99205C15.2995 6.44323 14.3684 6.74425 14.2572 7.50145L13.228 14.5107L6.87053 17.6366C6.18374 17.9743 6.18229 18.9529 6.86807 19.2926L13.2163 22.4374L14.2246 29.4497C14.3335 30.2072 15.2638 30.511 15.7988 29.9638L20.7513 24.8981L27.732 26.106C28.4861 26.2365 29.0625 25.4457 28.7074 24.7677L25.42 18.4922Z"
        fill="#F5F04F"
        stroke="#100423"
        strokeWidth="1.43755"
      />
    </svg>
  );
};

export default LargeStar;
