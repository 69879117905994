import React from 'react';
import LoadingSpinnerM from '@pedidosya/web-fenix/animations/LoadingSpinnerM';

const LoginPage = React.lazy(() => import('./Login'));

const LoginLoader = (props) => {
  return (
    <React.Suspense fallback={<LoadingSpinnerM />}>
      <LoginPage {...props} />
    </React.Suspense>
  );
};

export default LoginLoader;
