import React from 'react';

const MidStar = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.4075 16.2034L20.8199 13.9809C22.418 12.9401 22.1289 10.5186 20.3307 9.8832L16.491 8.52649L15.4318 4.59428C14.9358 2.75276 12.5435 2.27939 11.3835 3.79325L8.90666 7.02581L4.8396 6.81802C2.93493 6.72072 1.74546 8.84968 2.82678 10.4207L5.13574 13.7752L3.68134 17.579C3.00022 19.3604 4.65741 21.1495 6.48566 20.6066L10.3895 19.4472L13.5577 22.0059C15.0414 23.2042 17.2551 22.181 17.3037 20.2744L17.4075 16.2034Z"
      fill="#F5F04F"
      stroke="white"
      strokeWidth="4.19285"
    />
    <path
      d="M16.0487 15.4442L20.0681 12.8265C20.7094 12.4088 20.5934 11.4371 19.8718 11.1821L15.3491 9.58415L14.1016 4.95259C13.9025 4.21361 12.9425 4.02366 12.4771 4.63115L9.5597 8.43862L4.76931 8.19388C4.00499 8.15483 3.52768 9.00915 3.9616 9.63957L6.6812 13.5907L4.96813 18.071C4.69481 18.7859 5.35982 19.5038 6.09346 19.2859L10.6916 17.9204L14.4233 20.9341C15.0187 21.415 15.907 21.0044 15.9265 20.2393L16.0487 15.4442Z"
      fill="#F5F04F"
      stroke="#100423"
      strokeWidth="1.43755"
    />
  </svg>
);

export default MidStar;
