/* eslint-disable no-param-reassign */
const overrideOptions = (currentOptions, overrideableOptions) => ({
  ...currentOptions,
  ...overrideableOptions,
});

function AnimationOptions(initialOptions) {
  let options = { ...initialOptions };

  this.getOptions = function getOptions() {
    return options;
  };
  this.setOptions = function setOptions(newOptions) {
    options = newOptions;
  };
}

AnimationOptions.prototype.configure = function configure(overrideableOptions = {}) {
  this.setOptions(overrideOptions(this.getOptions(), overrideableOptions));
  return this;
};

AnimationOptions.prototype.getAsObject = function getAsObject() {
  return this.getOptions();
};

AnimationOptions.prototype.getAsCss = function getAsCss() {
  return Object.entries(this.getOptions()).reduce((accum, [key, value]) => {
    accum += `animation-${key}: ${value};`;
    return accum;
  }, '');
};

export default AnimationOptions;
