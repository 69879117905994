import React, { useEffect, useState } from 'react';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import isObject from '@commons/utils/object/isObject';
import { initMicrositeData } from './utils/microsite-data';
import MicrositeLoader from './MicrositeLoader';
import MicrositeError from './MicrositeError';
import MicrositeWrapper from './MicrositeWrapper';
import { PublicEnvProvider } from '@app/providers/PublicEnv';
import I18nProvider from '@app/providers/I18nProvider';
import CrossDeviceRoutesProvider from '@app/providers/CrossDeviceRoutesProvider';
import Authentication from './components/Authentication';
import ExternalServiceProvider from '@app/providers/ExternalServiceProvider';
import { getLogger } from '@app/logger';
import { getPlaform } from '@commons/utils/platform';

export const DEVICE = {
  MOBILE_PHONE: 'MOBILE_PHONE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};

const MicrositeInitializer = ({ shellData, AppComponent }) => {
  const [micrositeData, setMicrositeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    initMicrositeData(shellData)
      .then((response) => {
        if (response.status) {
          setMicrositeData(response.data);
        } else {
          setError(response.error);
        }
      })
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }, [shellData]);

  if (loading) {
    return <MicrositeLoader />;
  }

  let StateComponent = null;
  if (error || !micrositeData) {
    const errorMessage = `Error initializing microsite '${
      isObject(error) ? error.toString() : error
    }'`;
    const logger = getLogger();
    if (logger) {
      logger.error(errorMessage);
    }
    StateComponent = <MicrositeError debugMessage={errorMessage} />;
  } else {
    StateComponent = (
      <Authentication context={micrositeData?.context}>
        <ExternalServiceProvider externalServices={micrositeData?.externalServices}>
          <AppComponent {...micrositeData} />
        </ExternalServiceProvider>
      </Authentication>
    );
  }

  const debugMode = !micrositeData?.shellData?.isProduction;

  const device = micrositeData?.context?.device;
  const platform =
    device === DEVICE.DESKTOP ? 'web' : getPlaform(micrositeData?.context?.app?.platformKey);

  return (
    <MicrositeWrapper platform={platform}>
      <FenixFonts />
      <PublicEnvProvider
        debugMode={debugMode}
        context={micrositeData?.context}
        loader={<MicrositeLoader />}
      >
        <I18nProvider locale={micrositeData?.context?.locale} messages={micrositeData?.messages}>
          <CrossDeviceRoutesProvider device={micrositeData?.context?.device}>
            {StateComponent}
          </CrossDeviceRoutesProvider>
        </I18nProvider>
      </PublicEnvProvider>
    </MicrositeWrapper>
  );
};

export default MicrositeInitializer;
