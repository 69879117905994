import React from 'react';

const Bag = () => {
  return (
    <svg
      width="99"
      height="116"
      viewBox="0 0 99 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8403 7.52869C12.7367 7.71259 9.44871 11.1112 9.67469 13.6961L10.3712 23.4022L10.3713 23.4036L4.3474 37.1886C3.57841 39.0354 3.26775 41.0419 3.44207 43.0361L8.95733 106.125C9.32619 110.344 14.9964 114.54 19.2036 114.172L87.949 108.147C92.1562 107.779 97.0124 102.663 96.6435 98.444L90.411 34.2679C90.3208 33.2361 90.04 32.2374 89.5315 31.3356C87.4354 27.6181 81.3996 18.0992 81.3996 18.0992L81.2442 18.1127L80.2767 7.04521C80.0923 4.93552 77.3523 2.06387 73.5434 2.39684L14.8403 7.52869Z"
        fill="white"
      />
      <path
        d="M82.1584 17.6182C82.1584 17.6181 82.1584 17.6181 82.1058 17.6514L82.1058 17.6513L82.1583 17.618C82.1389 17.5874 82.1178 17.5582 82.0952 17.5306L81.1718 6.96697C81.0522 5.59986 80.146 4.14769 78.8278 3.10132C77.4831 2.03384 75.6221 1.31323 73.4651 1.50179L14.762 6.63364C14.0364 6.69707 13.2951 7.02317 12.6291 7.44998C11.9511 7.88451 11.2878 8.46395 10.7106 9.11707C9.59507 10.3794 8.63755 12.1111 8.77899 13.7669L9.4593 23.247L3.52411 36.8289L3.51796 36.8433C2.6932 38.824 2.36008 40.9758 2.54702 43.1143L8.06228 106.203C8.27722 108.662 9.99902 110.97 12.0933 112.581C14.1929 114.196 16.8807 115.277 19.2818 115.067L19.282 115.067L88.0273 109.042C88.0273 109.042 88.0274 109.042 88.0275 109.042C90.4284 108.832 92.8879 107.301 94.6753 105.346C96.4583 103.396 97.7535 100.824 97.5386 98.3658L97.5378 98.3572L91.306 34.1896C91.3059 34.1883 91.3058 34.187 91.3057 34.1857C91.2054 33.0443 90.8926 31.9203 90.3142 30.8944C89.2548 29.0155 87.2111 25.6895 85.4422 22.8484C84.5557 21.4246 83.7348 20.1172 83.1357 19.1658C82.836 18.69 82.5918 18.3033 82.4224 18.0353L82.2269 17.7263L82.1759 17.6457L82.1628 17.6251L82.1595 17.6198L82.1586 17.6185L82.1584 17.6182Z"
        stroke="white"
        strokeWidth="1.79693"
        strokeLinejoin="round"
      />
      <path
        d="M76.6051 19.2554L78.3914 19.0993C78.3914 19.0993 83.8824 28.1197 86.0524 31.9046C86.5876 32.8381 86.8834 33.8757 86.9771 34.9477L92.3303 96.1828C92.7082 100.506 89.5099 104.317 85.1866 104.695L78.1281 105.312L72.1684 37.1395C72.0764 36.0878 72.1756 35.0281 72.461 34.0118L76.6051 19.2554Z"
        fill="#FA0050"
        stroke="#100522"
        strokeWidth="1.97088"
        strokeLinejoin="round"
      />
      <path
        d="M13.8193 23.5017L77.0401 17.37L72.6289 32.6493C72.196 34.1488 72.0437 35.7154 72.1796 37.2702L78.1267 105.299L21.0494 110.288C16.7261 110.666 12.915 107.468 12.5371 103.145L7.27323 42.9315C7.09461 40.8883 7.41434 38.8323 8.20501 36.9398L13.8193 23.5017Z"
        fill="#FA0050"
        stroke="#100423"
        strokeWidth="1.97088"
        strokeLinejoin="round"
      />
      <path
        d="M77.494 18.2653C79.9828 46.735 82.4717 75.2046 84.9605 103.674"
        stroke="#100522"
        strokeWidth="1.97088"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2032 14.6731C13.0142 12.5115 14.6134 10.606 16.775 10.417L72.9963 5.50211C75.158 5.31313 77.0635 6.91229 77.2525 9.07392L78.077 18.506L14.0277 24.1052L13.2032 14.6731Z"
        fill="#FA0050"
        stroke="#100423"
        strokeWidth="1.97088"
        strokeLinejoin="round"
      />
      <path
        d="M46.7024 48.8162L46.415 48.8413L45.1196 48.9546L30.2037 50.2585C29.9737 50.2786 29.8033 50.4793 29.8232 50.7068L30.0284 53.0544C30.2524 55.6161 32.2044 56.869 35.5242 56.5788L47.2911 55.5501C48.5013 55.496 49.538 56.3943 49.6423 57.5877C49.7466 58.781 48.8816 59.8456 47.6803 60.0023L34.4888 61.1555C34.2928 61.1819 34.142 61.3406 34.1275 61.5357L32.0557 73.9886C32.0349 74.1145 32.074 74.2428 32.1617 74.3362C32.2491 74.4303 32.3758 74.479 32.5048 74.4681L36.6701 74.1039C37.8517 73.9759 38.8036 73.0849 38.9979 71.925L39.86 67.3842L48.2614 66.6497C53.2419 66.2143 56.9303 61.8692 56.4998 56.9446C56.0693 52.02 51.6829 48.3808 46.7024 48.8162Z"
        fill="white"
      />
    </svg>
  );
};

export default Bag;
