import React from 'react';
import styled from 'styled-components';
import {
  movementSmallStarLeft,
  smallStarExpand,
  movementLargeStarLeft,
  largStarLeft,
  movementLargeStarRight,
  movementMidStarLeft,
  movementMidStarRight,
  movementSmallStarRight,
  midStarLeft,
  midStarRight,
  smallStarRight,
  largeStarRight,
  fadeInBag,
} from './styles';
import Bag from './icons/Bag';
import SmallStar from './icons/SmallStar';
import LargeStar from './icons/LargeStar';
import MidStar from './icons/MidStar';

export const Container = styled.div`
  --bag-width: 164px;
  --bag-height: 197px;
  --stars-width: 110px;
  --stars-height: 80px;
  --animation-duration: 0.8s;
  --delay-stars: calc(var(--animation-duration) - 0.3s);
  --delay-stars-expand: calc(var(--animation-duration) + 1.3s);
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const StarContainer = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  right: ${({ right }) => right}px;

  & > svg {
    animation: ${({ animation }) => animation} 0.5s linear both,
      ${({ finalAnimation }) => finalAnimation} 1s linear forwards infinite;
    animation-delay: ${({ delay = 0 }) => `calc(var(--delay-stars) + ${delay}s)`},
      ${({ delay = 0 }) => `calc(var(--delay-stars-expand) + ${delay}s)`};
  }
`;

export const BagContainer = styled.div`
  z-index: 10;
  animation: ${fadeInBag} var(--animation-duration) ease-in-out;
`;

const CongratsAnimation = () => {
  return (
    <Container>
      <StarContainer
        top={80}
        left={120}
        animation={movementSmallStarLeft}
        finalAnimation={smallStarExpand}
      >
        <SmallStar />
      </StarContainer>
      <StarContainer
        top={80}
        left={130}
        animation={movementLargeStarLeft}
        delay={0.1}
        finalAnimation={largStarLeft}
      >
        <LargeStar />
      </StarContainer>
      <StarContainer
        top={80}
        left={130}
        animation={movementMidStarLeft}
        delay={0.3}
        finalAnimation={midStarLeft}
      >
        <MidStar />
      </StarContainer>
      <BagContainer>
        <Bag />
      </BagContainer>
      <StarContainer
        top={80}
        right={135}
        animation={movementSmallStarRight}
        finalAnimation={smallStarRight}
      >
        <SmallStar />
      </StarContainer>
      <StarContainer
        top={80}
        right={130}
        animation={movementMidStarRight}
        delay={0.1}
        finalAnimation={midStarRight}
      >
        <MidStar />
      </StarContainer>
      <StarContainer
        top={80}
        right={130}
        animation={movementLargeStarRight}
        delay={0.3}
        finalAnimation={largeStarRight}
      >
        <LargeStar />
      </StarContainer>
    </Container>
  );
};

export default CongratsAnimation;
