import React from 'react';
import WindowProvider from '@app/providers/WindowProvider';
import ErrorBoundary from '@app/components/ErrorBoundary';
import FenixProvider from '@pedidosya/web-fenix/contexts/FenixContext';

const MicrositeWrapper = ({ children, platform }) => {
  return (
    <ErrorBoundary>
      <WindowProvider>
        <FenixProvider platoform={platform}>{children}</FenixProvider>
      </WindowProvider>
    </ErrorBoundary>
  );
};

export default MicrositeWrapper;
