import useSWR from 'swr';
import { fetcher } from './fetcher';

const useCongratsData = (context, orderId) => {
  const { data, error, isLoading } = useSWR(
    [`${context.apiBaseUrl}/v1/orders/${orderId}/completed`, context?.app?.auth?.token],
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  return {
    data,
    error,
    isLoading,
  };
};

export default useCongratsData;
