import React from 'react';

const SmallStar = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7625 9.10597L11.0542 7.2808L11.4426 5.36193C11.7841 3.67424 10.1248 2.2762 8.51956 2.89913L6.69439 3.60739L4.77552 3.21905C3.08783 2.87749 1.6898 4.53678 2.31272 6.14205L3.02098 7.96722L2.63264 9.88609C2.29108 11.5738 3.95037 12.9718 5.55564 12.3489L7.38081 11.6406L9.29968 12.029C10.9874 12.3705 12.3854 10.7112 11.7625 9.10597Z"
      fill="white"
      stroke="white"
      strokeWidth="4.19285"
    />
    <path
      d="M9.6239 7.40302L10.0923 5.08866C10.2178 4.46836 9.60796 3.95452 9.01795 4.18347L6.81661 5.03771L4.50225 4.56932C3.88195 4.44379 3.36811 5.05365 3.59706 5.64366L4.4513 7.845L3.98292 10.1594C3.85738 10.7797 4.46724 11.2935 5.05725 11.0645L7.25859 10.2103L9.57295 10.6787C10.1933 10.8042 10.7071 10.1944 10.4781 9.60436L9.6239 7.40302Z"
      fill="white"
      stroke="#100423"
      strokeWidth="1.43755"
    />
  </svg>
);

export default SmallStar;
