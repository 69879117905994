import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoadingSpinnerM from '@pedidosya/web-fenix/animations/LoadingSpinnerM';
import actionManager from '@app/pages/completedOrder/actions';
import MicrositeError from '@app/components/Microsite/MicrositeError';
import useCongratsData from './useCongratsData';
import CongratsPage from './CongratsPage';

export const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CompletedOrderRevamp = ({ context, queryParams }) => {
  const { orderId } = queryParams;
  const { data, error, isLoading } = useCongratsData(context, orderId);

  useEffect(() => {
    if (data && data?.actions) {
      const actions = actionManager.buildHandler(data.actions);
      actions();
    }
  }, [data]);

  if (error) {
    return (
      <MicrositeError
        debugMessage={`Error getting Order Completed Response for orderId: ${orderId}`}
      />
    );
  }

  if (isLoading)
    return (
      <LoadingContainer data-testid="loading">
        <LoadingSpinnerM />
      </LoadingContainer>
    );

  return <CongratsPage data={data} />;
};

export default CompletedOrderRevamp;
